import Vue from "vue";
import VueRouter from "vue-router";
const index = () => import("../views/index");
const companyInfo = () => import("../views/companyInfo/index.vue");
const productInfo = () => import("../views/productInfo/index.vue");
const partnerShip = () => import("../views/partnerShip/index.vue");
const storeStyle = () => import("../views/storeStyle/index.vue");
const contact = () => import("../views/contact/index.vue");
const news = () => import("../views/news/index.vue");
const antiFake = () => import("../views/antiFake");
const antiFakeResult = () => import("../views/antiFake/result");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    component: index,
    meta: { title: "首页" }
  },
  {
    path: "/about",
    name: " company-info",
    component: companyInfo,
    meta: { title: "关于喜尔美" }
  },
  {
    path: "/products",
    name: "product-info",
    component: productInfo,
    meta: { title: "产品中心" }
  },
  {
    path: "/partnership",
    name: "partners-ship",
    component: partnerShip,
    meta: { title: "合作加盟" }
  },
  {
    path: "/store-style",
    name: "store-style",
    component: storeStyle,
    meta: { title: "加盟连锁店风采" }
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
    meta: { title: "联系我们" }
  },

  {
    path: "/information",
    name: "information",
    component: news,
    meta: { title: "咨询动态" }
  },
  {
    path: "/anti-fake",
    name: "anti-fake",
    component: antiFake,
    meta: { title: "防伪查询" }
  },
  {
    path: "/anti-result",
    name: "anti-result",
    component: antiFakeResult,
    meta: { title: "查询结果" }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});
router.afterEach((to, from, next) => {
  console.log(to)

  if(to.path=='/anti-result'){
    document.title = "商品追溯";
  }else{
    if (to.meta.title) { 
      document.title = "江西喜尔美喜庆用品连锁有限公司";
    }
  }
});

export default router;
