<template>
  <section class="header-container-pc">
    <div class="topbar-container">
      <div class="topbar-content type-area">
        <span>
          今天是 {{ timeFormat(new Date()) }}
          <span style="margin-left: 10px"></span> 欢迎光临本站
          <!-- 香港喜尔美（集团）投资管理有限公司 网址: www.hkxiermei.com -->
          <span class="adress">江西喜尔美喜庆用品连锁有限公司</span>
          <span class="net">网址： www.hkxiermei.com</span>
        </span>
      </div>
    </div>
    <div class="title-container">
      <div class="title-content wd-1200">
        <div class="logo" @click="toIndex"></div>
        <div class="nav">
          <ul>
            <li
              v-for="item in menuList"
              :key="item.path"
              @click="toPage(item)"
              :class="{ selected: $route.path == item.path }"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getNav } from "@/api/index";
export default {
  name: "headerIndex",
  data() {
    return {
      menuList: []
    };
  },
  created() {
    this.getNav();
  },
  mounted() {},
  methods: {
    toIndex() {
      location.reload();
    },
    // 时间日期过滤
    timeFormat(value) {
      const date = this.$day(value).format("YYYY年MM月DD日");
      let week = this.$day().day();
      switch (week) {
        case 0:
          week = "星期天";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
        default:
          week = "星期日";
          break;
      }
      return date + " " + week;
    },
    getNav() {
      const info = localStorage.getItem("mobileMenueInfo");
      if (info) {
        this.menuList = JSON.parse(info);
        this.$emit("getMenu");
      }
      const params = { siteId: "1305770326365442048" };
      getNav(params).then(res => {
        if (res.success) {
          this.menuList = res.result;
          localStorage.setItem(
            "mobileMenueInfo",
            JSON.stringify(this.menuList)
          );
          this.$emit("getMenu");
        } else {
          this.$message.error("请求失败，请刷新重试");
        }
      });
    },
    toPage(data) {
      this.$router.push(data.path);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/header.less";
</style>
