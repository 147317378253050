<template>
  <div class="header-container">
    <van-overlay @click="showMenue" :show="showOverlay" />
    <div class="menue" @click="showMenue">
      <van-icon :name="iconName" />
    </div>
    <nav id="sg-navbar" class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <template v-for="item in menuList">
          <li
            :key="item.path"
            @click="toPage(item)"
            :class="{ selected: $route.path == item.path }"
          >
            <div class="title">{{ item.title }}</div>
            <van-icon name="arrow" />
          </li>
        </template>
      </ul> 
    </nav>
    <div class="header">
      <div class="title-img" @click="toIndex">
        <img src="../assets/img/title-img.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getNav } from "@/api/index";
export default {
  props: ["isPc"],
  data() {
    return {
      menulistShow: false,
      iconName: "wap-nav",
      showOverlay: false,
      menuList: []
    };
  },

  methods: {
    toIndex() {
      location.reload();
    },
    getNav() {
      const info = localStorage.getItem("mobileMenueInfo");
      if (info) {
        this.menuList = JSON.parse(info);
        this.$emit("getMenu");
      }
      const params = { siteId: "1305770326365442048" };
      getNav(params).then(res => {
        if (res.success) {
          this.menuList = res.result;
          localStorage.setItem(
            "mobileMenueInfo",
            JSON.stringify(this.menuList)
          );
          this.$emit("getMenu");
        } else {
          this.$message.error("请求失败，请刷新重试");
        }
      });
    },
    toPage(data) {
      this.$router.push(data.path);
      this.showMenue();
    },
    showMenue() {
      this.menulistShow = !this.menulistShow;
      this.showOverlay = !this.showOverlay;
      this.iconName = this.iconName === "wap-nav" ? "cross" : "wap-nav";
      const num = this.menulistShow ? "-100%" : "0";
      const menue = document.querySelector("#sg-navbar");
      menue.style.transform = `translateX(${num})`;
      const height = this.menulistShow ? "100%" : "";
      const flow = this.menulistShow ? "hidden" : "auto";
      document.getElementsByTagName("body")[0].style.height = height;
      document.getElementsByTagName("body")[0].style.overflow = flow;
    }
  },
  created() {
    this.getNav();
  }
};
</script>

<style lang="less" scoped>
.header-container {
  background-color: #c90f2c;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.6);
  padding: 0 50px;
  position: relative;
  .menue {
    width: 39px;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    color: #fff09c;
    font-size: 60px;
    img {
      width: 100%;
    }
  }
  nav#sg-navbar {
    position: fixed;
    background: #fff;
    right: -81%;
    width: 80%;
    top: 0;
    display: block !important;
    -moz-transition: -moz-transform 0.5s ease;
    -o-transition: -o-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    border: none;
    height: 100vh;
    overflow-y: scroll;
    z-index: 1000;
    -moz-box-shadow: 0 6px 5px 5px rgba(0, 0, 0, 0.1),
      inset 0 3px 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 6px 5px 5px rgba(0, 0, 0, 0.1),
      inset 0 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 5px 5px rgba(0, 0, 0, 0.1),
      inset 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 100px 0;
    box-sizing: border-box;
    ul {
      li.selected {
        background-color: #c10320;
        color: #fff;
        .title {
          color: #fff;
        }
      }
      li {
        cursor: pointer;
        padding-left: 15px;
        // border-bottom: 1px solid #e5e5e5;
        display: flex;
        padding: 50px 40px;
        justify-content: space-between;
        align-items: center;
        font-size: 36px;
        color: #c10320;
        transition: all linear 0.3s;
        .title {
          font-size: 36px;
          color: #333333;
        }
      }
    }
  }
  .header {
    position: relative;
    width: 800px;
    height: 132px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-img {
      width: 269px;
    }
    .title {
      color: #fff09c;
      float: right;
      text-align: center;
      p {
        font-size: 48px;
        line-height: 55px;
        letter-spacing: 0px;
      }
      span {
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 6px;
        // @media screen and (min-width: 414px) {
        //   letter-spacing: 6px;
        // }
      }
    }
    .logo {
      width: 67px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
