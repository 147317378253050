<template>
  <div>
    <div class="footer-wrap" v-if="!isPc">
      <div class="phone-content">
        <div class="xiqing">
          <span>喜庆专线：</span>
          <span class="number">0551-65506159</span>
        </div>
        <div class="jiameng">
          <span>全国连锁加盟热线：</span>
          <span class="number">4008-8820-919</span>
        </div>
      </div>
      <div class="company-info">
        <p>江西喜尔美喜庆用品连锁有限公司</p>
        <p><span v-html="'免责声明  &nbsp; '"></span> 赣ICP备17001811号-1-1</p>
        <p>
          <span v-html="'地址  &nbsp; '"></span
          >江西省宜春市万载县马步乡喜尔美烟花爆竹物流基地
        </p>
      </div>
    </div>
    <section class="footer-container" v-else>
      <div class="footer-bottom">
        <div class="bottom-content type-area">
          <div class="copyright">
            江西喜尔美喜庆用品连锁有限公司 免责声明
            <a class="copy" target="_blank" href="https://beian.miit.gov.cn"
              >赣ICP备17001811号-1</a
            >
          </div>
          <div class="address">
            地址：江西省宜春市万载县马步乡喜尔美烟花爆竹物流基地
            <span class="margin-l10">喜庆专线：</span>
            <span class="number margin-l5">0551-65506159</span>
            <span class="margin-l10">全国连锁加盟热线：</span>
            <span class="number margin-l5">4008-8820-919</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["isPc"],
  data() {
    return {};
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
@import "../../../styles/footerPc.less";
.footer-wrap {
  color: #ffffff;
  padding: 30px 48px;
  height: 294px;
  background-color: #383838;
  text-align: left;
  .phone-content {
    height: 75px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .xiqing,
    .jiameng {
      width: 506px;
      height: 73px;
      text-align: center;
      background-color: #383838;
      border: solid 2px #f6f6f6;
      font-size: 24px;
      line-height: 73px;
      .number {
        font-size: 30px;
      }
    }
  }
  .company-info {
    p {
      font-size: 24px;
      line-height: 48px;
      span {
        color: #a6a6a6;
      }
    }
  }
}
</style>
