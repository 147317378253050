<template>
  <div id="app">
    <headerView class="header" @getMenu="getMenu" :isPc="isPc" v-if="!isPc" />
    <header-views v-else class="header" @getMenu="getMenu"></header-views>
    <router-view v-if="isRouterAlive" :mennu="mennu" :isPc="isPc" />
    <footer-view :isPc="isPc" v-if="isPc"></footer-view>
    <position-view :isPc="isPc" />
  </div>
</template>
<script>
import headerView from "./components/header";
import headerViews from "./components/headerPc";
import positionView from "@/components/position";
import footerView from "./views/index/components/footer";
export default {
  components: {
    headerView,
    headerViews,
    positionView,
    footerView
  },
  created() {
    this.isPc = window.innerWidth >= 1200;
    window.addEventListener("load", this.loadHandle);
  },
  mounted() {
    window.addEventListener("popstate", this.trigger, false);
    window.addEventListener("scroll", this.scrollChange);
  },
  provide: [],
  data() {
    return {
      mennu: false,
      isPc: true,
      isRouterAlive: true,
      historyData: [], // 历史路由数据
      nowTime: 0, // 当前所在历史的时间戳
      timer: false
    };
  },
  methods: {
    historyRoute(val) {
      let param = {
        url: val.path,
        time: new Date().getTime()
      };
      window.history.replaceState(param, " ", location.origin);
      this.historyData.push(param);
    },
    trigger(e) {
      let that = this;
      let time = e.state.time;
      if (that.nowTime === 0) {
        // 第一次一定是后退按钮
        // console.log("后退");
      } else {
        if (time >= that.nowTime) {
          // 前进
          // console.log("前进");
        } else {
          // 后退
          // console.log("后退");
        }
      }
      that.nowTime = time;
      that.toHistory(that.nowTime);
    },
    toHistory(time) {
      let data = this.historyData.find(e => e.time === time);
      this.$router.replace(data.url);
      window.history.replaceState({}, " ", location.origin);
      // console.log()
    },
    getMenu() {
      this.mennu = true;
    },
    scrollChange() {
      const that = this;
      if (!this.isPc) {
        return false;
      }
      // const dom = document.querySelector(".header-pc");
      // const scrollHeight = document.documentElement.scrollTop;
      // if (dom.offsetHeight < scrollHeight) {
      //   if (dom.offsetHeight) {
      //     dom.classList.add("header-hidden");
      //   }
      //   if (that.timer) clearTimeout(that.timer);
      //   that.timer = setTimeout(() => {
      //     dom.classList.remove("header-hidden");
      //   }, 200);
      // }
    },
    loadHandle() {
      window.addEventListener("resize", this.resizeHandle);
    },
    resizeHandle() {
      this.isPc = window.innerWidth >= 1200;
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },

  watch: {
    $route: {
      handler(val) {
        this.historyRoute(val);
      },
      immediate: true
    }
  },
  beforeDestroy() {
    window.removeEventListener("load", this.loadHandle);
    window.removeEventListener("resize", this.resizeHandle);
    window.removeEventListener("popstate", this.trigger, false);
    window.removeEventListener("scroll", this.scrollChange, false);
  }
};
</script>

<style lang="less">
@import "./styles/index.less";
#app {
  position: relative;
  .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all linear 0.8s;
  }
  .header-hidden {
    // position: static;
  }
}
</style>
