import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import ElementUI from 'element-ui';
import day from "dayjs";
Vue.prototype.$day = day;
Vue.use(ElementUI);

import "amfe-flexible";
// 按需引入vant
import "./libs/vant";

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
